import React from 'react';
import resume from './Extracurricular resume.pdf';

const Resume = () => {
  return (
    <div style={{ padding: '2rem' }}>
      <h2 style={{ fontSize: '2.2rem', fontWeight: 'bold', marginBottom: '2rem' }}>Extracurricular Resume</h2>
      
      <div style={{ 
        width: '100%', 
        height: '1100px', 
        border: '1px solid #e2e8f0',
        borderRadius: '8px',
        padding: '2rem',
        backgroundColor: 'white',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
      }}>
        <iframe
          src={resume}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Extracurricular Resume"
        />
      </div>
    </div>
  );
};

export default Resume;